@import "_variables.scss";
@import "_hooks.scss";
@import "~uikit/src/scss/variables-theme.scss";
@import "~uikit/src/scss/mixins-theme.scss";
@import "~uikit/src/scss/uikit-theme.scss";
@import "_custom.scss";


html[dir=rtl]  {
    @import "_rtl.scss";
}

* {
    box-sizing: border-box;
}

